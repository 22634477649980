import { createRenderEffect, onMount } from "solid-js";
import { utmStoreGetAll } from "~/lib/utm_store";
import type { GtmVarsValues } from "~/types/common";

type Props = {
  values: GtmVarsValues;
};

export default function GtmVars(props: Props) {
  let script = `window.dataLayer = window.dataLayer || [];
  window.dataLayer[0] = window.dataLayer[0] || {};`;

  createRenderEffect(() => {
    Object.entries(props.values).forEach(([key, value]) => {
      script +=
        `window.dataLayer[0]["${key}"] = ` + JSON.stringify(value) + ";\n";
    });
  });

  return <script type="text/javascript" innerHTML={script} />;
}

export function GtmVarsGlobal(props: { cog_env: string }) {
  const deviceType = function () {
    const n = window.innerWidth;
    return n < 768 ? "m" : n <= 1024 ? "t" : "d";
  };

  onMount(() => {
    // @ts-expect-error - dataLayer is a global variable
    window.dataLayer[0]["all.device"] = deviceType();
    // @ts-expect-error - dataLayer is a global variable
    window.dataLayer[0]["all.URL"] = window.location.href;
    // @ts-expect-error - dataLayer is a global variable
    window.dataLayer[0]["all.URI"] = window.location.pathname;

    Object.entries(utmStoreGetAll()).forEach(([key, value]) => {
      // @ts-expect-error - dataLayer is a global variable
      window.dataLayer[0][key] = value ?? "";
    });

    // @ts-expect-error - dataLayer is a global variable
    window.dataLayer[0]["cog_a"] = window.location.search.includes("cog_a")
      ? window.location.search.split("cog_a=")[1].split("&")[0]
      : null;
    // @ts-expect-error - dataLayer is a global variable
    window.dataLayer[0]["cog_z"] = window.location.search.includes("cog_z")
      ? window.location.search.split("cog_z=")[1].split("&")[0]
      : null;

    // @ts-expect-error - dataLayer is a global variable
    window.dataLayer[0]["all.envWork"] = props.cog_env;
    // @ts-expect-error - dataLayer is a global variable
    window.dataLayer[0]["all.language"] = "fr";
  });

  const script = `function cogdt () { const n = window.innerWidth; return n < 768 ? "m" : n <= 1024 ? "t" : "d"; };
    window.dataLayer = window.dataLayer || [];
    window.dataLayer[0] = window.dataLayer[0] || {};
    window.dataLayer[0]["all.envWork"] = "${props.cog_env}";
    window.dataLayer[0]["all.language"] = "fr";
    window.dataLayer[0]["all.device"] = cogdt();
    window.dataLayer[0]["all.URL"] = window.location.href;
    window.dataLayer[0]["all.URI"] = window.location.pathname;`;

  return <script type="text/javascript" innerHTML={script} />;
}
